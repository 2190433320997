import useQuery from '@shared/hooks/src/useQuery'
import { QK } from '@shared/utils'

import PatientEncountersApi from '@services/PatientEncounters.api'

export default function usePatientEncounter(patientId, encounterId, options = {}) {
  return useQuery({
    queryKey: QK.patients.id(patientId).encounters.id(encounterId).details,
    queryFn: () => PatientEncountersApi.details(patientId, encounterId),
    ...options,
  })
}
