import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import { imageNotAvailable } from '@shared/utils'

import api from './index'

const PatientsApi = {
  // GET
  patient: (patientId) =>
    api.get(`/patients/${patientId}`).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  cbos: (patientId, query, config) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/cbos`, query }), config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ===================== CONSENT =====================
  consents: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/consents`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  giveConsent: (patientId, data, config) =>
    api
      .patch(`/patients/${patientId}/consents`, data, config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []), // ===================== APPOINTMENTS =====================
  appointment: (patientId, appointmentId) =>
    api
      .get({ url: `/patients/${patientId}/appointments/${appointmentId}` })
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  appointments: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/appointments`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  availabilities: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/availabilities`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  questionnaires: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/questionnaires`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ===================== LABS =====================
  lab: (patientId, labId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/labs/${labId}`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  updateLab: (patientId, labId, data) =>
    api
      .patch(`/patients/${patientId}/labs/${labId}`, { lab: data })
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  labs: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/labs`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  labFile: (patientId, fileId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/labs_files/${fileId}`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  labFileRename: (patientId, fileId, data) =>
    api
      .patch(`/patients/${patientId}/labs_files/${fileId}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  labNotes: (patientId, labId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/labs/${labId}/notes`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  createLabNote: (patientId, labId, data) =>
    api
      .post(`/patients/${patientId}/labs/${labId}/notes`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  // ===================== PHOTOS =====================
  photoId: (patientId) =>
    api
      .get(`/patients/${patientId}/photo_id`, { skipHandling: true })
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {})
      .catch(() => Promise.resolve({ id: 'error', photoId: imageNotAvailable })),

  // ===================== PRESCRIPTIONS =====================
  prescription: (patientId, rxId, config) =>
    api
      .get(`/patients/${patientId}/prescriptions/${rxId}`, config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  prescriptions: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/prescriptions`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ===================== PROVIDERS =====================
  providers: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/providers`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  // ================= ENCOUNTER TYPES =====================
  encounterTypes: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/encounter_types`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ===================== DOCUMENTS =====================
  document: (patientId, documentId) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/documents/${documentId}` }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  documents: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/documents`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ===================== ASSESSMENTS =====================
  assessment: (patientId, assessmentId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/assessments/${assessmentId}`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  assessments: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/assessments`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ===================== INSURANCES =====================
  insurance: (patientId, insuranceId, config) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/insurances/${insuranceId}` }), config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  insurances: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/insurances`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ===================== TREATMENT =====================
  treatments: (patientId) =>
    api.get(`/patients/${patientId}/treatment_types`).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ====================== GILEAD =======================
  gileadApplication: (patientId, gileadApplicationId) =>
    api
      .get(`/patients/${patientId}/gilead/applications/${gileadApplicationId}`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  declineGileadApplication: (patientId, gileadApplicationId, data) =>
    api
      .post(`/patients/${patientId}/gilead/applications/${gileadApplicationId}/decline`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  // ====================== ROI =======================
  roiRequest: (patientId, roiRequestId) =>
    api
      .get(`/patients/${patientId}/roi_requests/${roiRequestId}`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  declineRoiRequest: (patientId, roiRequestId, data) =>
    api
      .post(`/patients/${patientId}/roi_requests/${roiRequestId}/decline`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  updateTreatment: (patientId, data) =>
    api
      .put(`/patients/${patientId}/treatment_types`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // POST
  create: (data) => api.post('/patients', data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  createAppointment: (patientId, data) =>
    api.post(`/patients/${patientId}/appointments`, data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  createInsurance: (patientId, data) =>
    api.post(`/patients/${patientId}/insurances`, data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  uploadPhotoId: (patientId, data) => api.post(`/patients/${patientId}/photo_id`, data),
  uploadDocument: (patientId, data, config) => api.post(`/patients/${patientId}/documents`, data, config),

  // PATCH
  updateAppointment: (patientId, appointmentId, data) =>
    api
      .patch(`/patients/${patientId}/appointments/${appointmentId}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  cancelAppointment: (patientId, appointmentId) =>
    api
      .patch(`/patients/${patientId}/appointments/${appointmentId}/cancel`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  update: (patientId, data) =>
    api.patch(`/patients/${patientId}`, data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  updateDocuments: (patientId, documentId, data) =>
    api
      .patch(`/patients/${patientId}/documents/${documentId}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  updateInsurance: (patientId, insuranceId, data) =>
    api
      .patch(`/patients/${patientId}/insurances/${insuranceId}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  updateQuestionnaires: (patientId, data) =>
    api
      .patch(`/patients/${patientId}/questionnaires`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  // DELETE
  removePhotoId: (patientId, reason) => api.delete(`/patients/${patientId}/photo_id`, { data: { reason } }),
  removeDocument: (patientId, documentId) => api.delete(`/patients/${patientId}/documents/${documentId}`),
  removeInsurance: (patientId, insuranceId) => api.delete(`/patients/${patientId}/insurances/${insuranceId}`),
}

export default PatientsApi
