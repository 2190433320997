import { useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import { QK } from '@shared/utils'

import CbosApi from '@services/Cbos.api'

export function useCBORuleSets(cboId, options = {}) {
  return useQuery({
    queryKey: QK.cbos.id(cboId).ruleSets,
    queryFn: () => CbosApi.ruleSets(cboId),
    ...options,
  })
}

export function useCBORuleSetsUpdate(cboId) {
  return useMutation({
    mutationFn: (data) => CbosApi.updateRuleSets(cboId, data),
    onSuccess: (data) => queryClient.setQueryData(QK.cbos.id(cboId).ruleSets, data),
  })
}
