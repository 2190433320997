import useQuery from '@shared/hooks/src/useQuery'
import PatientsApi from '@shared/services/src/Patients.api'
import { QK } from '@shared/utils'

export default function usePatientAvailableProviders(patientId, query, options = {}) {
  return useQuery({
    queryKey: QK.patients.id(patientId).providers.list(query),
    queryFn: () => PatientsApi.providers(patientId, query),
    ...options,
  })
}
