import { useMemo } from 'react'

import useCurrentUserId from '@shared/hooks/src/useCurrentUserId'
import usePubNub, { Channels } from '@shared/hooks/src/usePubNub'
import useQuery from '@shared/hooks/src/useQuery'
import MeSharedProvider from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import UsersSharedApi from '@shared/services/src/Users.api'
import { QK, UserRole } from '@shared/utils'

import PharmaciesApi from '@services/Pharmacies.api'

/**
 * Wraps shared MeProvider with a query to fetch the current user,
 * and subscribes to PubNub events for the current user.
 */
export default function MeProvider({ children }) {
  const userId = useCurrentUserId()
  const key = QK.me(userId).details

  const { data } = useQuery({ queryKey: key, queryFn: UsersSharedApi.me })
  const { data: pharmacy } = useQuery({
    queryKey: QK.pharmacies.id(data?.pharmacyId).details,
    queryFn: () => PharmaciesApi.details(data?.pharmacyId),
    enabled: Boolean(data) && data.role === UserRole.Pharmacy && Boolean(data.pharmacyId),
  })

  const user = useMemo(() => {
    if (data?.role === UserRole.Pharmacy) {
      return { ...data, pharmacy }
    }

    return data
  }, [data, pharmacy])

  const id = user?.id
  const providerId = user?.provider?.id

  usePubNub(
    `user_${id}`,
    ({ action, attributes }) => {
      if (action === Channels.NewMessage) {
        return queryClient.setQueryData(key, (prev) => ({
          ...prev,
          unreadConversationsCount: attributes.unread_conversations,
        }))
      }

      if (action === Channels.UnreviewedLabs) {
        return queryClient.setQueryData(key, (prev) => ({
          ...prev,
          unreviewedLabsCount: attributes.count,
        }))
      }

      if (action === Channels.UpdateQuarterlyFlowCount) {
        return queryClient.setQueryData(key, (prev) => ({
          ...prev,
          pendingFlows: attributes.pending_count,
        }))
      }

      if (action === Channels.ReassignedPatient) {
        if (user?.role === UserRole.Pharmacy) {
          queryClient.invalidateQueries({ queryKey: QK.pharmacies.tasks.lists })
          queryClient.invalidateQueries({ queryKey: QK.pharmacies.tasks.details })
        }
        if (user?.role === UserRole.Provider && providerId) {
          queryClient.invalidateQueries({ queryKey: QK.tasks.lists })
          queryClient.invalidateQueries({ queryKey: QK.providers.id(providerId).dashboard.labs.lists })
          queryClient.invalidateQueries({ queryKey: QK.providers.id(providerId).dashboard.assessments.lists })
          queryClient.invalidateQueries({ queryKey: QK.providers.id(providerId).dashboard.parTasks.lists })
        }
      }
    },
    { enabled: Boolean(id) }
  )

  return <MeSharedProvider value={user}>{children}</MeSharedProvider>
}
