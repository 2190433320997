import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { Device } from '@twilio/voice-sdk'
import { useReadLocalStorage } from 'usehooks-ts'

import usePubNub, { Channels } from '@shared/hooks/src/usePubNub'
import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import useDevices from '@shared/twilio/src/hooks/useDevices'
import { SELECTED_AUDIO_INPUT_KEY, SELECTED_AUDIO_OUTPUT_KEY } from '@shared/twilio/src/utils'
import { Logger, QK, VoiceCallStatus } from '@shared/utils'

import PhoneCallsApi from '@services/PhoneCalls.api'

const log = Logger('VoiceCallProvider.hooks.js')

export function useToken({ userId, externalCall }) {
  const query = { user_id: userId, external_user: externalCall }

  return useQuery({
    queryKey: QK.calls.token(query),
    queryFn: () => PhoneCallsApi.token(query),
    // Fetch only once
    staleTime: 60 * 1000,
    enabled: Boolean(userId) || externalCall,
    select: (data) => data.token,
  })
}

/**
 * Fetches calls for a given patient or appointment and subscribes to call status changes.
 * Provides a function to update the call status for immediate UI feedback.
 */
export function useCall({ userId, appointmentId, externalCall, callSid, disableHistory = false }) {
  const query = useMemo(() => {
    return {
      ...(appointmentId ? { appointment_id: appointmentId } : { user_id: userId }),
      external_user: externalCall,
      order: 'desc',
      limit: 1,
    }
  }, [appointmentId, externalCall, userId])

  const call = useQuery({
    queryKey: QK.calls.list(query),
    queryFn: () => PhoneCallsApi.list(query),
    enabled: !disableHistory && (Boolean(userId) || Boolean(appointmentId) || Boolean(externalCall)),
    select: (data) => data?.[0],
  })

  const updateCall = useCallback(
    (data) =>
      queryClient.setQueryData(QK.calls.list(query), (prev = []) => {
        // Update the first item in the list of calls
        return [{ ...prev[0], ...data }, ...prev.slice(1)]
      }),
    [query]
  )

  // Subscribe to call status changes
  usePubNub(
    `phone_call_status_${callSid}`,
    ({ action, attributes }) => {
      if (action === Channels.CallStatusChanged) {
        const { status, start_time: startTime, end_time: endTime } = attributes
        updateCall({ status, startTime, endTime })

        if (status === VoiceCallStatus.Busy) toast.error('Call declined')
        if (status === VoiceCallStatus.NoAnswer) toast.error('No answer')
        if (status === VoiceCallStatus.Failed) toast.error('Call could not be completed')
      }
    },
    { enabled: Boolean(callSid) }
  )

  useEffect(() => {
    return () => queryClient.removeQueries({ queryKey: QK.calls.list(query) })
  }, [query])

  return [call, updateCall]
}

/**
 * Responsible for managing the TWILIO device state.
 */
export function useTwilioDeviceState({ onError } = {}) {
  const [device, setDevice] = useState(null)

  const { audioInputDevices, audioOutputDevices } = useDevices()

  const selectedAudioInputId = useReadLocalStorage(SELECTED_AUDIO_INPUT_KEY)
  const selectedAudioOutputId = useReadLocalStorage(SELECTED_AUDIO_OUTPUT_KEY)

  const registerDevice = useCallback(
    async (token) => {
      if (!token) return
      if (device) device.destroy()

      try {
        // Creates the Twilio Voice Device basis for this context
        const device = new Device(token, { codecPreferences: ['opus', 'pcmu'], enableRingingState: true })

        device.on('registered', () => {
          log.info('Voice Device registered')
          setDevice(device)
        })

        device.on('destroyed', () => {
          log.info('Voice Device destroyed')
        })

        device.on('error', (error) => {
          log.error('Voice Device error')
          onError?.(error)
        })

        await device.register()
      } catch (e) {
        log.error('Voice Device could not register', e)
      }
    },
    [device, onError]
  )

  // Set the input and output to the ones from the local storage if they are available.
  useEffect(() => {
    const updateDevices = async () => {
      if (!device) return undefined

      // Clear previous tracks before setting the new ones.
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      stream.getTracks().forEach((track) => track.stop())

      // Check if the device has audio capabilities.
      if (!device.audio) return undefined

      // Track https://github.com/twilio/twilio-voice.js/issues/104
      const inputDevice = audioInputDevices.find((d) => d.deviceId === selectedAudioInputId)
      device.audio.setInputDevice(inputDevice?.deviceId || 'default').catch(() => {
        log.warn('Could not set specified input device')
        // Select the default input device if the selected input device is not available.
        device.audio.setInputDevice('default').catch(() => {
          log.warn('Could not set default device')
        })
      })

      // Track https://github.com/twilio/twilio-voice.js/issues/104
      const outputDevice = audioOutputDevices.find((d) => d.deviceId === selectedAudioOutputId)
      device.audio.speakerDevices.set(outputDevice?.deviceId || 'default').catch(() => {
        log.warn('Could not set specified speaker device')
        // Select the default output device if the selected output device is not available.
        device.audio.speakerDevices.set('default').catch(() => {
          log.warn('Could not set default speaker device')
        })
      })
    }

    updateDevices().catch((e) => {
      log.warn('Could not update devices', e)
      return onError?.(e)
    })
  }, [audioInputDevices, audioOutputDevices, device, onError, selectedAudioInputId, selectedAudioOutputId])

  // Destroy the device when the component unmounts.
  useEffect(() => {
    return () => device?.destroy()
  }, [device])

  return [device, registerDevice]
}

/**
 * Responsible for managing the TWILIO call state.
 */
export function useTwilioCallState({ onError } = {}) {
  const [callSid, setCallSid] = useState('')
  const [call, setCall] = useState(null)

  const completeTheCall = (call) => {
    call?.removeAllListeners()
    setCall(null)
    // Wait 5 seconds before resetting the callSid to allow the last events to be processed.
    setTimeout(() => setCallSid(''), 5000)
  }

  useEffect(() => {
    if (!call) return

    call.on('accept', (call) => {
      log.info('Voice Call received', call)
      setCall(call)
      setCallSid(call.parameters.CallSid)
    })
    call.on('connect', (call) => {
      log.info('Voice Call connected', call)
      setCall(call)
    })
    call.on('disconnect', (call) => {
      log.info('Voice Call disconnected', call)
      completeTheCall(call)
    })
    call.on('cancel', (call) => {
      log.info('Voice Call cancelled', call)
      completeTheCall(call)
    })
    call.on('error', (e) => {
      log.error('Voice Call failed', e)
      return onError?.(e)
    })
  }, [call, onError, setCall])

  return [callSid, call, setCall]
}

export function usePatientDirectCall(patient) {
  const page = useRef()
  const disabled = !patient || patient.disabled || !patient.phone

  const makeCall = useCallback(() => {
    if (!patient) return
    if (page.current && !page.current.closed) return page.current.focus()
    page.current = undefined
    const proxy = window.open(
      `${import.meta.env.VITE_URL}/call/patient/${patient.id}`,
      `patient-direct-call-${patient.id}`,
      'popup=true,height=850,width=600'
    )
    if (!proxy) return toast.error('Please allow popups for this website')
    page.current = proxy
  }, [patient])

  return useMemo(() => {
    return { disabled, makeCall }
  }, [disabled, makeCall])
}

export function useUserDirectCall(user) {
  const page = useRef()
  const disabled = !user || !user.phone

  const makeCall = useCallback(() => {
    if (!user) return
    if (page.current && !page.current.closed) return page.current.focus()
    page.current = undefined
    const proxy = window.open(
      `${import.meta.env.VITE_URL}/call/user/${user.id}`,
      `user-direct-call-${user.id}`,
      'popup=true,height=400,width=600'
    )
    if (!proxy) return toast.error('Please allow popups for this website')
    page.current = proxy
  }, [user])

  return useMemo(() => {
    return { disabled, makeCall }
  }, [disabled, makeCall])
}
