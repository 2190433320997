import { keepPreviousData, useInfiniteQuery, useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import AppointmentsSharedApi from '@shared/services/src/Appointments.api'
import { flatten, handleError, pageParam, QK } from '@shared/utils'

import AppointmentsApi from '@services/Appointments.api'

export function useAppointment(appointmentId, options = {}) {
  return useQuery({
    queryKey: QK.appointments.id(appointmentId).details,
    queryFn: () => AppointmentsSharedApi.appointment(appointmentId),
    ...options,
  })
}

const NOTES_LIMIT = 5

export function useAppointmentNotes(appointmentId) {
  const query = { limit: NOTES_LIMIT }
  return useInfiniteQuery({
    queryKey: QK.appointments.id(appointmentId).notes.list(query),
    queryFn: ({ pageParam }) => AppointmentsApi.notes(appointmentId, { ...query, offset: pageParam * NOTES_LIMIT }),
    placeholderData: keepPreviousData,
    enabled: Boolean(appointmentId),
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(NOTES_LIMIT),
  })
}

export function useAppointmentNoteCreation(appointmentId, onCreate) {
  return useMutation({
    mutationFn: (message) => AppointmentsSharedApi.createNote(appointmentId, message),
    onSuccess: () => {
      onCreate?.()
      return queryClient.invalidateQueries({ queryKey: QK.appointments.id(appointmentId).notes.lists })
    },
    onError: handleError,
  })
}

export function useMarkAppointmentAsMissed() {
  const me = useMe()

  return useMutation({
    mutationFn: (id) => AppointmentsApi.markAsMissed(id),
    onSuccess: (appointment) => {
      queryClient.setQueryData(QK.appointments.id(appointment.id).details, (old) => ({ ...old, ...appointment }))
      queryClient.invalidateQueries({ queryKey: QK.appointments.lists })
      if (appointment.provider.id === me.provider?.id) {
        queryClient.invalidateQueries({ queryKey: QK.providers.id(me.provider.id).appointments.lists })
      }
    },
    onError: (e) => handleError(e, { message: 'Failed to mark appointment as missed' }),
  })
}

export function useMarkAppointmentAsCanceled() {
  const me = useMe()

  return useMutation({
    mutationFn: (id) => AppointmentsApi.markAsCanceled(id),
    onSuccess: (appointment) => {
      queryClient.setQueryData(QK.appointments.id(appointment.id).details, (old) => ({ ...old, ...appointment }))
      queryClient.invalidateQueries({ queryKey: QK.appointments.lists })
      if (appointment.provider.id === me.provider?.id) {
        queryClient.invalidateQueries({ queryKey: QK.providers.id(me.provider.id).appointments.lists })
      }
    },
    onError: (e) => handleError(e, { message: 'Failed to cancel appointment' }),
  })
}

export const invalidateEncounterNotes = (appointment) => {
  if (!appointment) return

  const patientId = appointment?.userId
  const encounterId = appointment?.encounterId

  if (!patientId || !encounterId) return

  queryClient.invalidateQueries({ queryKey: QK.patients.id(patientId).encounters.id(encounterId).notes.lists })
}
