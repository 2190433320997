import useQuery from '@shared/hooks/src/useQuery'
import PatientsSharedApi from '@shared/services/src/Patients.api'
import { QK } from '@shared/utils'

export function useInsurance(patientId, insuranceId) {
  return useQuery({
    queryKey: QK.patients.id(patientId).insurances.id(insuranceId).details,
    queryFn: () => PatientsSharedApi.insurance(patientId, insuranceId, { skipHandling: true }),
    enabled: Boolean(patientId) && Boolean(insuranceId),
  })
}
