import { useCallback, useMemo, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useLocalStorage, useTimeout } from 'usehooks-ts'

import UserToursApi from '@shared/services/src/UserTours.api'
import { QK } from '@shared/utils'

export default function useTourState(userId, key) {
  // Wait a second before running any tour
  const [ready, setReady] = useState(false)
  useTimeout(() => setReady(true), 1000)

  const [tourState, updateTourState] = useLocalStorage(key, { run: true, stepIndex: 0 })

  const finishTour = useMutation({
    mutationFn: () => UserToursApi.update(userId, key, true),
  })

  const handleFinish = useCallback(() => {
    finishTour.mutateAsync().then((tour) => {
      window?.queryClient.setQueryData(QK.users.id(userId).tours.list(), (oldData) => ({ ...oldData, [key]: tour }))
      window?.queryClient.setQueryData(QK.users.id(userId).tours.id(key).details, () => tour)
      localStorage.removeItem(key)
    })
  }, [finishTour, key, userId])

  return useMemo(() => {
    return {
      // Override the 'run' state if the tour is not ready
      tourState: { ...tourState, run: ready ? tourState.run : false },
      updateTourState,
      finishTour: handleFinish,
    }
  }, [handleFinish, ready, tourState, updateTourState])
}
