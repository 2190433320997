import PatientsSharedApi from '@shared/services/src/Patients.api'
import { QK } from '@shared/utils'

import useQuery from './useQuery'

export default function usePatient(id, options = {}) {
  return useQuery({
    queryKey: QK.patients.id(id).details,
    queryFn: () => PatientsSharedApi.patient(id),
    ...options,
  })
}
