import { deserialize } from 'deserialize-json-api'

import api from '@shared/services'

const MetricsApi = {
  adminMetrics: () => api.get('/admin_metrics').then((resp) => resp.data),
  cbosCoverage: () => api.get('/cbos_map_coverages').then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
}

export default MetricsApi
