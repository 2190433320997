import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'

const PatientsApi = {
  // GET
  list: (query) =>
    api
      .get(QS.stringifyUrl({ url: '/patients', query }, { arrayFormat: 'bracket' }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data),
  labVendors: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/lab_vendors`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  notes: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/notes`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  addNote: (patientId, message) => api.post(`/patients/${patientId}/notes`, { message }),
  tasks: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/tasks`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  task: (patientId, taskId) =>
    api.get(`/patients/${patientId}/tasks/${taskId}`).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  taskNotes: (patientId, taskId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/tasks/${taskId}/notes`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  addTaskNote: (patientId, taskId, message) => api.post(`/patients/${patientId}/tasks/${taskId}/notes`, { message }),
  insuranceSummary: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/insurance_summary`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  soapNote: (patientId, appointmentId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/appointments/${appointmentId}/soap_notes`, query }))
      .then((res) => deserialize(res.data)?.data ?? []),
  events: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/events`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  roiMedicalRecord: (patientId, medicalRecordId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/roi_medical_records/${medicalRecordId}`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  previewRoiMedicalRecords: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/roi_medical_records/preview`, query }, { arrayFormat: 'bracket' }), {
        timeout: Infinity,
        responseType: 'blob',
      })
      .then((res) => res.data),
  accessible: (patientId) => api.get(`/patients/${patientId}/accessible`).then((res) => res.data),

  // REPORTS
  report: (query) =>
    api
      .get(QS.stringifyUrl({ url: '/patients/reports', query }, { arrayFormat: 'bracket' }), {
        timeout: Infinity,
        responseType: 'blob',
      })
      .then((res) => res.data),
  cboAssignmentReport: (query) =>
    api
      .get(QS.stringifyUrl({ url: '/patients/reports/cbo_assignment', query }, { arrayFormat: 'bracket' }), {
        timeout: Infinity,
        responseType: 'blob',
      })
      .then((res) => res.data),
  hivReport: (query) =>
    api
      .get(QS.stringifyUrl({ url: '/patients/reports/hiv', query }, { arrayFormat: 'bracket' }), {
        timeout: Infinity,
        responseType: 'blob',
      })
      .then((res) => res.data),
  insuranceReport: (query) =>
    api
      .get(QS.stringifyUrl({ url: '/patients/reports/insurance', query }, { arrayFormat: 'bracket' }), {
        timeout: Infinity,
        responseType: 'blob',
      })
      .then((res) => res.data),
  PADispositionReport: ({ variant, ...query }) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/reports/pa_disposition_${variant}`, query }, { arrayFormat: 'bracket' }), {
        timeout: Infinity,
        responseType: 'blob',
      })
      .then((res) => res.data),

  // POST
  sendInsurancePartnerInvite: (patientId) => api.post(`/patients/${patientId}/cbo_insurance_partners/send_invite`),
  setInsurancePartnerContacted: (patientId) => api.post(`/patients/${patientId}/cbo_insurance_partners`, { date_type: 'contacted' }),
  setInsurancePartnerInsured: (patientId) => api.post(`/patients/${patientId}/cbo_insurance_partners`, { date_type: 'insured' }),
  sendConsent: (patientId) => api.post(`/patients/${patientId}/send_consent`),
  sendROIRequest: (patientId) => api.post(`/patients/${patientId}/roi_requests`),
  resendAppointmentInvitation: (patientId, appointmentId) => api.post(`/patients/${patientId}/appointments/${appointmentId}/send_invite`),
  orderLabs: (patientId, data) => api.post(`/patients/${patientId}/labs`, data),
  exportEvents: (patientId) =>
    api
      .get(`/patients/${patientId}/events/export`, {
        timeout: Infinity,
        responseType: 'blob',
      })
      .then((res) => res.data),
  exportHealthHistory: (patientId) =>
    api
      .get(`/patients/${patientId}/health_histories/export`, {
        timeout: Infinity,
        responseType: 'blob',
      })
      .then((res) => res.data),
  submitGileadApplication: (patientId, data) => api.post(`/patients/${patientId}/gilead/applications`, data).then((res) => res.data),
  breakGlass: (patientId, data) => api.post(`/patients/${patientId}/break_glass`, data).then((res) => res.data),
  resetBreakGlass: (patientId, config) => api.delete(`/patients/${patientId}/break_glass`, config).then((res) => res.data),
  workNoteSubmit: (patientId, data) => api.post(`/patients/${patientId}/work_notes`, data).then((res) => res.data),
  priorAuthSubmit: (patientId, data) => api.post(`/patients/${patientId}/prior_auths`, data).then((res) => res.data),
  priorAuthPreview: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/prior_auths/preview`, query }), {
        timeout: Infinity,
        responseType: 'blob',
      })
      .then((res) => res.data),
  sendPushNotification: (patientId, data) => api.post(`/patients/${patientId}/push_notifications`, data).then((res) => res.data),
  createRoiMedicalRecords: (patientId, data) =>
    api
      .post(`/patients/${patientId}/roi_medical_records`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  faxRoiMedicalRecord: (patientId, medicalRecordId, data) =>
    api
      .post(`/patients/${patientId}/roi_medical_records/${medicalRecordId}/fax`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  // PATCH
  updateSoapNote: (patientId, appointmentId, schemaId, soapData, complete = false) => {
    const url = `/patients/${patientId}/appointments/${appointmentId}/soap_notes`
    return api
      .patch(url, { soap_note_schema_id: schemaId, soap_note: soapData, complete: complete.toString() })
      .then((res) => deserialize(res.data)?.data ?? [])
  },

  // PUT
  signGileadApplication: (patientId, gileadApplicationId) =>
    api
      .put(`/patients/${patientId}/gilead/applications/${gileadApplicationId}/provider_sign`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
}

export default PatientsApi
