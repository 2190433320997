import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useReadLocalStorage } from 'usehooks-ts'

import useLoadingState from '@shared/hooks/src/useLoadingState'
import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { AppointmentScheduleTypes, AppointmentStatus, BuildEnv, flatten, pageParam, QK, TreatmentType } from '@shared/utils'

import useBusinessHours from '@hooks/useBusinessHours'
import AppointmentsApi from '@services/Appointments.api'
import PlatformSettingsApi from '@services/PlatformSettings.api'
import ProvidersApi from '@services/Providers.api'

import { CalendarEvent, eventsFactory, format, ScheduleType } from './Calendar.utils'

const LIMIT = 50
const isProd = import.meta.env.VITE_BUILD_ENV === BuildEnv.Production

function useWeekAvailability(providerId, timezone, date) {
  const overriddenLimit = useReadLocalStorage('override-page-size') || LIMIT
  const limit = isProd ? LIMIT : overriddenLimit

  const query = {
    limit,
    start_date: date.startOf('week').format(format),
    end_date: date.endOf('week').format(format),
  }

  const queryResult = useInfiniteQuery({
    queryKey: QK.providers.id(providerId).availabilities.list(query),
    queryFn: ({ pageParam }) => ProvidersApi.availabilities(providerId, { ...query, offset: pageParam * limit }),
    enabled: Boolean(providerId),
    select: ({ pages }) => {
      const data = pages.flat()
      return eventsFactory(data, CalendarEvent.Availability, timezone)
    },
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })

  useInfiniteScroll(queryResult)

  return queryResult
}

function useWeekAdminTimes(providerId, timezone, date) {
  const overriddenLimit = useReadLocalStorage('override-page-size') || LIMIT
  const limit = isProd ? LIMIT : overriddenLimit

  const query = {
    limit,
    start_date_time: date.startOf('week').format(format),
    end_date_time: date.endOf('week').format(format),
  }

  const queryResult = useInfiniteQuery({
    queryKey: QK.providers.id(providerId).adminTimes.list(query),
    queryFn: ({ pageParam }) => ProvidersApi.adminTimes(providerId, { ...query, offset: pageParam * limit }),
    enabled: Boolean(providerId),
    select: ({ pages }) => {
      const data = pages.flat()
      return eventsFactory(data, CalendarEvent.AdminTime, timezone)
    },
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })

  useInfiniteScroll(queryResult)

  return queryResult
}

function useWeekAppointments(providerId, timezone, date, scheduleType) {
  const overriddenLimit = useReadLocalStorage('override-page-size') || LIMIT
  const limit = isProd ? LIMIT : overriddenLimit

  const query = {
    start_date: date.startOf('week').format(format),
    end_date: date.endOf('week').format(format),
    appointment_status: [
      AppointmentStatus.Missed,
      AppointmentStatus.Scheduled,
      AppointmentStatus.Waiting,
      AppointmentStatus.InProgress,
      AppointmentStatus.Documenting,
      AppointmentStatus.Complete,
    ],
    sched_type: scheduleType === ScheduleType.All ? undefined : AppointmentScheduleTypes.Scheduled,
    limit,
  }

  const queryResult = useInfiniteQuery({
    queryKey: QK.providers.id(providerId).appointments.list(query),
    queryFn: ({ pageParam }) => AppointmentsApi.appointments(providerId, { ...query, offset: pageParam * limit }),
    enabled: Boolean(providerId),
    select: ({ pages }) => {
      const data = pages.flat()
      const events = eventsFactory(data, CalendarEvent.Appointment, timezone)
      return { data, events }
    },
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })

  useInfiniteScroll(queryResult)

  return queryResult
}

function useWeekAdHocAppointments(providerId, timezone, date) {
  const overriddenLimit = useReadLocalStorage('override-page-size') || LIMIT
  const limit = isProd ? LIMIT : overriddenLimit

  const query = {
    start_date: date.startOf('week').format(format),
    end_date: date.endOf('week').format(format),
    appointment_status: [
      AppointmentStatus.Missed,
      AppointmentStatus.Scheduled,
      AppointmentStatus.Waiting,
      AppointmentStatus.InProgress,
      AppointmentStatus.Documenting,
      AppointmentStatus.Complete,
    ],
    limit,
  }

  const queryResult = useInfiniteQuery({
    queryKey: QK.providers.id(providerId).adHocAppointments.list(query),
    queryFn: ({ pageParam }) => AppointmentsApi.adHocList(providerId, { ...query, offset: pageParam * limit }),
    enabled: Boolean(providerId),
    select: ({ pages }) => {
      const data = pages.flat()
      const events = eventsFactory(data, CalendarEvent.AdHocAppointment, timezone)
      return { data, events }
    },
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })

  useInfiniteScroll(queryResult)

  return queryResult
}

function useBlackoutPeriods(providerId, timezone, date, businessHours) {
  const overriddenLimit = useReadLocalStorage('override-page-size') || LIMIT
  const limit = isProd ? LIMIT : overriddenLimit

  const query = {
    limit,
    start_date_time: date.startOf('week').format(format),
    end_date_time: date.endOf('week').format(format),
  }

  const queryResult = useInfiniteQuery({
    queryKey: QK.platform.blackouts.list(query),
    queryFn: ({ pageParam }) => PlatformSettingsApi.blackoutPeriods({ ...query, offset: pageParam * limit }),
    enabled: Boolean(providerId) && Boolean(businessHours),
    select: ({ pages }) => {
      const data = pages.flat()
      return eventsFactory(data, CalendarEvent.Blackout, timezone, { businessHours })
    },
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })

  useInfiniteScroll(queryResult)

  return queryResult
}

function useCompletedEncounters(providerId, timezone, date) {
  const providerTypes = useLookup(Lookup.ProviderTypes)
  const overriddenLimit = useReadLocalStorage('override-page-size') || LIMIT
  const limit = isProd ? LIMIT : overriddenLimit

  const query = {
    limit,
    provider_type_id: providerTypes[TreatmentType.OralPrEP],
    start_date: date.startOf('week').format(format),
    end_date: date.endOf('week').format(format),
  }

  const queryResult = useInfiniteQuery({
    queryKey: QK.providers.id(providerId).completedEncounters.list(query),
    queryFn: ({ pageParam }) => ProvidersApi.completedEncounters(providerId, { ...query, offset: pageParam * limit }),
    enabled: Boolean(providerId),
    select: (data) => data.pages.flat().length,
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })

  useInfiniteScroll(queryResult)

  return queryResult
}

function useInfiniteScroll(queryResult) {
  const { fetchNextPage, hasNextPage, isFetchingNextPage } = queryResult

  useLayoutEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])
}

export const useDate = (timezone) => {
  const [date, setDate] = useState(timezone ? dayjs().tz(timezone) : dayjs())

  useEffect(() => {
    if (!timezone) return
    setDate(dayjs(date).tz(timezone))
  }, [timezone]) // eslint-disable-line react-hooks/exhaustive-deps

  return [date, setDate]
}

export function useCalendarData({ user, date, scheduleType }) {
  const providerId = user?.provider.id
  const timezone = user?.provider.timezone

  const { data: businessHours, isPending: areBusinessHoursPending } = useBusinessHours()
  const { data: completedEncounters, isFetching: areCompletedEncountersLoading } = useCompletedEncounters(providerId, timezone, date)
  const { data: availabilities, isFetching: areAvailabilitiesLoading } = useWeekAvailability(providerId, timezone, date)
  const { data: adminTimes, isFetching: areAdminTimesLoading } = useWeekAdminTimes(providerId, timezone, date)
  const { data: appointments, isFetching: areAppointmentsLoading } = useWeekAppointments(providerId, timezone, date, scheduleType)
  const { data: adHocAppointments, isFetching: areAdHocAppointmentsLoading } = useWeekAdHocAppointments(providerId, timezone, date)
  const { data: blackoutPeriods, isFetching: areBlackoutPeriodsLoading } = useBlackoutPeriods(providerId, timezone, date, businessHours)

  const isLoading = useLoadingState(
    areCompletedEncountersLoading ||
      areAppointmentsLoading ||
      areAdHocAppointmentsLoading ||
      areAdminTimesLoading ||
      areAvailabilitiesLoading ||
      areBlackoutPeriodsLoading ||
      areBusinessHoursPending
  )

  const events = useMemo(() => {
    return []
      .concat(availabilities || [])
      .concat(adminTimes || [])
      .concat(appointments?.events || [])
      .concat(adHocAppointments?.events || [])
      .concat(blackoutPeriods || [])
  }, [availabilities, adminTimes, appointments, adHocAppointments, blackoutPeriods])

  return {
    events,
    isLoading,

    businessHours,
    completedEncounters,
    availabilities,
    adminTimes,
    appointments,
    adHocAppointments,
    blackoutPeriods,
  }
}
