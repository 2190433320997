import { useLocation, useNavigate } from 'react-router-dom'
import { keepPreviousData, useInfiniteQuery, useMutation } from '@tanstack/react-query'

import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import { flatten, mapCache, pageParam, QK } from '@shared/utils'

import UserNotificationsApi from '@services/UserNotifications.api'
import { NotificationType } from '@pages/Notifications/Notifications.utils'

const LIMIT = 20

export function useUserNotifications(params = {}, options = {}) {
  const me = useMe()
  const query = { ...params, limit: LIMIT }
  return useInfiniteQuery({
    queryKey: QK.users.id(me.id).notifications.list(query),
    queryFn: ({ pageParam }) => UserNotificationsApi.list(me.id, { ...query, offset: pageParam * LIMIT }),
    placeholderData: keepPreviousData,
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(LIMIT),
    ...options,
  })
}

export function useUnreadNotificationsCount() {
  const { data, hasNextPage } = useUserNotifications({ read: false }, { enabled: false })

  const count = data ? `${data.filter((n) => !n.read).length}${hasNextPage ? '+' : ''}` : ''
  if (['0', '0+'].includes(count)) return ''
  return count
}

export function useNotificationUpdate() {
  const me = useMe()
  return useMutation({
    mutationFn: ({ id, read }) => {
      if (read) return UserNotificationsApi.markRead(me.id, id)
      return UserNotificationsApi.markUnread(me.id, id)
    },
    onSuccess: (notification, { id }) => {
      return queryClient.setQueriesData(
        { queryKey: QK.users.id(me.id).notifications.lists },
        mapCache((item) => (item.id === id ? { ...item, ...notification } : item))
      )
    },
  })
}

export function useMarkAllRead() {
  const me = useMe()
  return useMutation({
    mutationFn: () => UserNotificationsApi.markAllRead(me.id),
    onSuccess: () => {
      return queryClient.setQueriesData(
        { queryKey: QK.users.id(me.id).notifications.lists },
        mapCache((item) => ({ ...item, read: true }))
      )
    },
  })
}

export function useNotificationOnClick(notification) {
  const navigate = useNavigate()
  const currentPath = useLocation().pathname
  const update = useNotificationUpdate()

  const isVisitPage = currentPath.startsWith('/app/visits')

  return () => {
    if (!notification) return

    if (notification.read === false) update.mutate({ id: notification.id, read: true })

    if (notification.type === NotificationType.Message && notification.properties?.patientId) {
      const chatPath = notification.properties?.messageId
        ? `/app/patients/${notification.properties.patientId}?patientTab=chat&messageId=${notification.properties.messageId}`
        : `/app/patients/${notification.properties.patientId}?patientTab=chat`

      return isVisitPage ? window.open(`${import.meta.env.VITE_URL}${chatPath}`, '_blank') : navigate(chatPath)
    }

    if (notification.type === NotificationType.ReassignedPatient && notification.properties?.patientId) {
      const patientPath = `/app/patients/${notification.properties.patientId}`

      return isVisitPage ? window.open(`${import.meta.env.VITE_URL}${patientPath}`, '_blank') : navigate(patientPath)
    }

    if (notification.type === NotificationType.DoseSpot && notification.properties?.url) {
      window.open(notification.properties.url, '_blank')
    }
  }
}
