import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'

const PlatformNotificationsApi = {
  list: (query) =>
    api
      .get(QS.stringifyUrl({ url: '/platform_notifications', query }, { arrayFormat: 'bracket' }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  create: (data) =>
    api.post('/platform_notifications', data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  update: (notificationId, data) =>
    api
      .put(`/platform_notifications/${notificationId}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
}

export default PlatformNotificationsApi
