import { useLayoutEffect } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { chain } from 'lodash'
import { useReadLocalStorage } from 'usehooks-ts'

import { useMe } from '@shared/providers/src/MeProvider'
import { BuildEnv, pageParam, QK } from '@shared/utils'

import ProvidersApi from '@services/Providers.api'

export const FORMAT = 'YYYY-MM-DD HH:mm'
export const LIMIT = 50
const isProd = import.meta.env.VITE_BUILD_ENV === BuildEnv.Production

export default function useAvailability({ startDate, endDate }) {
  const overriddenLimit = useReadLocalStorage('override-page-size') || LIMIT
  const limit = isProd ? LIMIT : overriddenLimit

  const me = useMe()
  const id = me.provider.id

  const query = { limit, start_date: startDate.format(FORMAT), end_date: endDate.format(FORMAT) }

  const queryResult = useInfiniteQuery({
    queryKey: QK.providers.id(id).availabilities.list(query),
    queryFn: ({ pageParam }) => ProvidersApi.availabilities(id, { ...query, offset: pageParam * limit }),
    select: ({ pages }) => {
      const data = pages.flat()
      return chain(data)
        .groupBy((v) => dayjs(v.start).tz(window.timezone).format('YYYY-MM-DD'))
        .value()
    },
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = queryResult

  useLayoutEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  return queryResult
}
