import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'

const AdminCBOsApi = {
  list: (query) =>
    api
      .get(QS.stringifyUrl({ url: `/admin/cbos`, query }, { arrayFormat: 'bracket' }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  updateStatus: (cboId, data) =>
    api
      .patch(`/admin/cbos/${cboId}/update_status`, data)
      .then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),
}

export default AdminCBOsApi
