import useQuery from '@shared/hooks/src/useQuery'
import { QK } from '@shared/utils'

import PlatformSettingsApi from '@services/PlatformSettings.api'

export default function useBusinessHours() {
  return useQuery({
    queryKey: QK.platform.businessHours,
    queryFn: PlatformSettingsApi.businessHours,
  })
}
