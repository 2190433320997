import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'

const MessageMentionsApi = {
  list: (userId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/users/${userId}/message_mentions`, query }, { arrayFormat: 'bracket' }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
}

export default MessageMentionsApi
