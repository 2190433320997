import { useLayoutEffect, useMemo } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'

import useLoadingState from '@shared/hooks/src/useLoadingState'
import { useMe } from '@shared/providers/src/MeProvider'
import { flatten, pageParam, QK, UserRole } from '@shared/utils'

import QPhrasesApi from '@services/QPhrases.api'
import { LIMIT } from '@pages/QPhrases/QPhrases.hooks'

function useCategoryQPhrases(category) {
  const me = useMe()
  const query = { limit: LIMIT, status: 'active', category }

  const queryResult = useInfiniteQuery({
    queryKey: QK.qPhrases.list(query),
    queryFn: ({ pageParam }) => QPhrasesApi.list({ ...query, offset: pageParam * LIMIT }),
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(LIMIT),
    enabled: Boolean(me) && me.role !== UserRole.Patient,
  })

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = queryResult

  useLayoutEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  return queryResult
}

export function useAllQPhrasesQuery() {
  const teamPhrases = useCategoryQPhrases('team')
  const personalPhrases = useCategoryQPhrases('personal')

  const data = useMemo(() => {
    return (teamPhrases.data || []).concat(personalPhrases.data || [])
  }, [personalPhrases.data, teamPhrases.data])

  const isLoading = useLoadingState(teamPhrases.isLoading || personalPhrases.isLoading)

  return { data, isLoading }
}
