import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'

const SystemUsersApi = {
  list: (query) =>
    api
      .get(QS.stringifyUrl({ url: '/system_users', query }, { arrayFormat: 'bracket' }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data),

  details: (userId) => api.get(`/system_users/${userId}`).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data),
}

export default SystemUsersApi
