import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'

const PharmaciesApi = {
  list: (query) =>
    api
      .get(QS.stringifyUrl({ url: `/pharmacies`, query }, { arrayFormat: 'bracket' }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  details: (id) => api.get(`/pharmacies/${id}`).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  delete: (pharmacyId) =>
    api.delete(`/pharmacies/${pharmacyId}`).then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),

  update: (pharmacyId, data) =>
    api.patch(`/pharmacies/${pharmacyId}`, data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  updateIndicators: (pharmacyId, data) =>
    api
      .patch(`/pharmacies/${pharmacyId}/update_24h_and_pep`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  create: (data) => api.post(`/pharmacies`, data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  export: (query) =>
    api
      .get(QS.stringifyUrl({ url: '/pharmacies/reports/patient_assignment', query }, { arrayFormat: 'bracket' }), {
        timeout: Infinity,
        responseType: 'blob',
      })
      .then((res) => res.data),

  tasks: (query) =>
    api
      .get(QS.stringifyUrl({ url: '/pharmacies/tasks', query }, { arrayFormat: 'bracket' }))
      .then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? []),
  task: (taskId, config) =>
    api.get(`/pharmacies/tasks/${taskId}`, config).then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),
  markReturned: (taskId, data) =>
    api
      .post(`/pharmacies/tasks/${taskId}/mark_returned_pharmacy`, data)
      .then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),
}

export default PharmaciesApi
