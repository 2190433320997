import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'

const PhoneCallsApi = {
  list: (query) =>
    api.get(QS.stringifyUrl({ url: `/phone_calls`, query })).then((res) => {
      return deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []
    }),
  token: (query) => api.get(QS.stringifyUrl({ url: `/phone_calls/phone_token`, query })).then((resp) => resp.data),
}

export default PhoneCallsApi
