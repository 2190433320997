import useQuery from '@shared/hooks/src/useQuery'
import { QK, UserRole } from '@shared/utils'

import PatientsApi from '@services/Patients.api'
import SystemUsersApi from '@services/SystemUsers.api'

export function useUsers({ role, search, limit = 15, status }, options = {}) {
  const query = { role, search_term: search, status, limit }

  const isPatient = role === UserRole.Patient
  const queryKey = isPatient ? QK.patients.list(query) : QK.systemUsers.list(query)
  const queryFn = isPatient ? () => PatientsApi.list(query) : () => SystemUsersApi.list(query)

  return useQuery({ queryKey, queryFn, ...options })
}
