import { useMutation } from '@tanstack/react-query'
import intersection from 'lodash/intersection'
import { useTheme } from '@mui/material/styles'

import useQuery from '@shared/hooks/src/useQuery'
import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import { handleError, QK } from '@shared/utils'

import CbosApi from '@services/Cbos.api'
import MetricsApi from '@services/Metrics.api'
import { FilterType } from '@components/TableFilters'

export default function useCBOsCoverage(services, options = {}) {
  const theme = useTheme()

  return useQuery({
    queryKey: QK.cbos.coverage,
    queryFn: MetricsApi.cbosCoverage,
    select: (data) => mapCBOsData(data, services, theme),
    ...options,
  })
}

export function usePriorityToggle(state) {
  return useMutation({
    mutationFn: (cbo) => {
      if (cbo.prioritized) {
        return CbosApi.removePriority(cbo.id, { state })
      }
      return CbosApi.prioritize(cbo.id, { state })
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QK.cbos.coverage }),
    onError: (error) => handleError(error, { showResponse: true }),
  })
}

const mapCBOsData = (data = [], services = [], theme) => {
  const priorities = data.reduce((acc, cbo) => {
    cbo.statesWithPriority?.forEach((state) => {
      acc[state] = cbo.id
    })
    return acc
  }, {})

  const cbos = data
    .filter((cbo) => cbo.acceptingNewPatients && cbo.coveredCounties?.length)
    .map((cbo) => ({ ...cbo, services: cbo.cbosProviderTypes.map(({ name }) => name) }))
    .filter((cbo) => services.length === 0 || intersection(cbo.services, services).length)

  return cbos.reduce((acc, cbo) => {
    cbo.coveredCounties.forEach(({ state, counties }) => {
      const isFullyCovered = !counties || counties.length === 0
      let stateData = acc[state] || { fill: theme.palette.warning.light, cbos: [] }
      if (isFullyCovered) stateData.fill = theme.palette.success.main

      const cboData = {
        id: cbo.id,
        name: cbo.name,
        logo: cbo.logo,
        counties,
        services: cbo.services,
        isFullyCovered,
        prioritized: priorities[state] === cbo.id,
      }

      if (cboData.prioritized) {
        stateData.cbos.unshift(cboData)
      } else {
        stateData.cbos.push(cboData)
      }

      acc[state] = stateData
    })

    return acc
  }, {})
}

export const useFiltersSchema = () => {
  const providerTypes = useLookup(Lookup.ProviderTypes)

  return [
    {
      key: 'services',
      type: FilterType.MULTI_SELECT,
      options: Object.keys(providerTypes).map((value) => ({ label: value, value })),
    },
  ]
}
