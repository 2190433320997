import useQuery from '@shared/hooks/src/useQuery'
import { QK } from '@shared/utils'

import PatientsApi from '@services/Patients.api'

export default function useInsuranceSummary(patientId, options = {}) {
  return useQuery({
    queryKey: QK.patients.id(patientId).insurances.summary,
    queryFn: () => PatientsApi.insuranceSummary(patientId),
    enabled: Boolean(patientId),
    ...options,
  })
}
