import { useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import CbosSharedApi from '@shared/services/src/Cbos.api'
import { mapCache, QK } from '@shared/utils'

import AdminCBOsApi from '@services/AdminCBOs.api'
import CbosApi from '@services/Cbos.api'

export function useCBO(cboId, options = {}, config) {
  return useQuery({
    queryKey: QK.cbos.id(cboId).details,
    queryFn: () => CbosSharedApi.cbo(cboId, config),
    ...options,
  })
}

export function useCBOStatusUpdate() {
  return useMutation({
    mutationFn: ({ id, status }) => AdminCBOsApi.updateStatus(id, { status }),
    onSuccess: (response, { id, status }) => {
      return queryClient.setQueriesData(
        { queryKey: QK.admin.cbos.lists },
        mapCache((old) => (old.id === id ? { ...old, data: status } : old))
      )
    },
  })
}

export function useCBOUpdate(cboId) {
  return useMutation({
    mutationFn: (data) => CbosSharedApi.updateCBO(cboId, data),
    onSuccess: (data) => queryClient.setQueryData(QK.cbos.id(cboId).details, data),
  })
}

export function useCBOContractedPharmacies(cboId, query, options = {}) {
  return useQuery({
    queryKey: QK.cbos.id(cboId).contractedPharmacies.list(query),
    queryFn: () => CbosApi.contractedPharmacies(cboId, query),
    ...options,
  })
}
