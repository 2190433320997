import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'

const CbosApi = {
  cbos: (query, config) => {
    return api
      .get(QS.stringifyUrl({ url: '/cbos', query }), config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? [])
  },
  cbo: (cboId, config) =>
    api.get(`/cbos/${cboId}`, config).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  updateCBO: (cboId, data) =>
    api.patch(`/cbos/${cboId}`, data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
}

export default CbosApi
